import React, { useEffect } from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import Menu from './Menu';
import LoginPage from './LoginPage';
import env from "react-dotenv";
import RegisterPage from './RegisterPage';
import ForgotPassword from './ForgotPassword';
import ResetPassword from './ResetPassword';
import Profile from './Profile';
import ChangePassword from './ChangePassword';
import ListSystems from './ListSystems';
import OpenSystem from './OpenSystem';

function RootComponent() {
  useEffect(() => {
    const pingInterval = 60000; // 60 seconds
    let lastPingTime = 0;

    function handleVisibilityChange() {
      const currentTime = new Date().getTime();
      if (document.visibilityState === 'visible' && (currentTime - lastPingTime > pingInterval)) {
        wakeServer();
        console.log("WAKEWAKE");
        lastPingTime = currentTime;
      }
    }

    function handleFocus() {
      const currentTime = new Date().getTime();
      if (currentTime - lastPingTime > pingInterval) {
        wakeServer();
        console.log("WAKEWAKE");
        lastPingTime = currentTime;
      }
    }

    function getXmlHttpRequestObject() {
      return new XMLHttpRequest();
    }

    function wakeServer() {
      const xhr = getXmlHttpRequestObject();
      xhr.onreadystatechange = dataCallback;
      const url = env.REACT_APP_HOST + "/";
      xhr.open("GET", url, true);
      xhr.send(null);
    }

    function dataCallback() {
      if (this.readyState === 4 && this.status === 200) {
        console.log(this.responseText);
      }
    }

    document.addEventListener('visibilitychange', handleVisibilityChange);
    window.addEventListener('focus', handleFocus);

    // Initial wake server call
    wakeServer();

    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
      window.removeEventListener('focus', handleFocus);
    };
  }, []);

  return (
    <>
      <Menu />
      {decideMainContent()}
    </>
  );
}

function decideMainContent() {  
  const queryParams = new URLSearchParams(window.location.search);
  const page = queryParams.get('page');
  switch(page) {
    case 'Login':
      return (<LoginPage />);
    case 'Register':
      return (<RegisterPage />);
    case 'ForgotPassword':
      return (<ForgotPassword />);
    case 'ResetPassword':
      return (<ResetPassword />);
    case 'Profile':
      return (<Profile />);
    case 'Systems':
      return (<ListSystems />);
    case 'ChangePassword':
      return (<ChangePassword />);
    case 'OpenSystem':
      return (<OpenSystem />);
    default:
      return (<App />);
  }
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <RootComponent />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
