import React, {useState} from 'react'
import './RegisterPage.css'
import env from "react-dotenv";
import PasswordStrengthBar from 'react-password-strength-bar';

export default function ForgotPassword() {
    const [email, setEmail] = useState('');
    const [error, setError] = useState('');
    var xhr = null;
    return(
        <div className="form">
            
            <div className="form-body">
                <div className="email">
                    <label className="form__label" htmlFor="email">Email </label>
                    <input  type="email" id="email" className="form__input" placeholder="Enter email" onChange={event => setEmail(event.target.value)}/>
                </div>
                <p className="errorText">{error}</p>
            </div>
            <div className="footer">
                <button type="submit" className="btn btn-primary" onClick={() => RetreivePassword()}>Retreive password</button>
            </div>
        </div>      
      )

    function isValidEmail(email) {
        return /\S+@\S+\.\S+/.test(email);
    }
    function RetreivePassword(){
        if(!isValidEmail(email)){
            setError("The email you have entered is not a valid email address.");
            return;
        }
        setError("");
        sendReceivePassword();
    }

    function getXmlHttpRequestObject() {
        if (!xhr) {
            // Create a new XMLHttpRequest object 
            xhr = new XMLHttpRequest();
        }
        return xhr;
    };
    function sendReceivePassword() {
      xhr = getXmlHttpRequestObject();
      xhr.onreadystatechange = receivePasswordCallback;
      // asynchronous requests
      var request = env.REACT_APP_HOST + "/retreivePassword"
      xhr.open("POST", request, true);
      xhr.setRequestHeader("Content-Type", "application/json;charset=UTF-8");
      // Send the request over the network
      xhr.send(JSON.stringify({ "email" : email}));
    }   
    function receivePasswordCallback() {
      // Check response is ready or not
      if (xhr.readyState == 4 && xhr.status == 201) {
          setError("Email will be sent with instructions on how to retreive your account.");
        //   window.location.reload(false);
      }
      else if (xhr.readyState == 4){
        setError("Could not retreive password due to: " + xhr.responseText);
      }
    }
}
