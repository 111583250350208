import './App.css';
import React, {useState, useEffect, useRef } from 'react'
import env from "react-dotenv";




function App() {
  var xhr = null;
  useEffect(() => { //Start
    wakeServer();
  }, []);


function getXmlHttpRequestObject() {
    if (!xhr) {
        // Create a new XMLHttpRequest object 
        xhr = new XMLHttpRequest();
    }
    return xhr;
};
  function wakeServer() {
    xhr = getXmlHttpRequestObject();
    xhr.onreadystatechange = dataCallback;
    // asynchronous requests
    var url = env.REACT_APP_HOST + "/";
    xhr.open("GET", url, true);
    // Send the request over the network
    xhr.send(null);
  }
  function dataCallback() {
    // Check response is ready or not
    if (xhr.readyState == 4 && xhr.status == 200) {
        // Log to console
        console.log(xhr.responseText)
    }
}
  return (
    <div className="App">
      <div className="information">
        <h2 className="infoHeader">
          Build and save your bridge system
        </h2>
        <p className="infoText">Collaborate with friends</p>
      </div>
      <div className="previewPictures">
        <div className="previewText">
          <h3 className="headerPreviewText">
            System declaration in your browser
          </h3>
          <p>
            Explore BSB on your computer, phone or tablet. Invite friends to collaborate, build your system declaration and take your game to the next level.
          </p>
        </div>
        <div className="previewImgDiv row">
          <div className="col-5 previewInneDiv">
            <img className="previewImg"  src={require("./bidBoxPics/previewPicture.png")}/>
          </div>
          <div className="col-5 previewInneDiv">
            <img className="previewImg"  src={require("./bidBoxPics/previewPictureEnterBid.png")}/>
          </div>
        </div>
      </div>
      <div className="instructions">
        <div className='instructionsRow'>
          <div className='circle'><p className="circleText">1</p></div>
          <div>
            <p className='instructionText'>
            You need to register an account to start building your system declaration. Hit Login/Register > Register > fill in your information.
            Once you have created your account, go to the Systems tab to create/view system declarations.
            </p>
          </div>
        </div>
        <div className='instructionsRow'>
          <div className='circle'><p className="circleText">2</p></div>
          <div>
            <p className='instructionText'>
              When you have created a system declaration you can start entering bids and invite friends to collaborate and develop your system.
            </p>
          </div>
        </div>
      </div>
      <div className="bottom">
        © 2024, Bridge System Builder
      </div>
    </div>
  );
}

export default App;
