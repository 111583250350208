import React, {useState, useEffect, useRef } from 'react'
import "bootstrap/dist/css/bootstrap.min.css"
import "./LoginPage.css"
import env from "react-dotenv";


export default function LoginPage() {
    
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');

    var xhr = null;

  return (
    <div className="Auth-form-container">
      <form className="Auth-form" onSubmit={login}>
        <div className="Auth-form-content"> 
          <h3 className="Auth-form-title">Sign In</h3>
          <div className="form-group mt-3">
            <label>Username</label>
            <input
              id="username"
              type="username"
              className="form-control mt-1"
              placeholder="Enter username"
              onChange={event => setUsername(event.target.value)}
              value={username}
            />
          </div>
          <div className="form-group mt-3">
            <label>Password</label>
            <input
              id="password"
              type="password"
              className="form-control mt-1"
              placeholder="Enter password"
              onChange={event => setPassword(event.target.value)}
              value={password}
            />
          </div>
          <div className="d-grid gap-2 mt-3">
            <button type="submit" className="btn btn-primary">
              Submit
            </button>
          </div>
          <p className="forgot-password text-right mt-2">
            Forgot <a href="#" onClick={() => changeView('ForgotPassword')}>password?</a><br></br> New user? <a href="#register" onClick={() => changeView('Register')}>Register here</a>
          </p>
        </div>
      </form>
    </div>
  )
  function changeView(view){
    sessionStorage.setItem(env.REACT_APP_LOCAL_STORAGE_CURRENT_VIEW, view);
    window.location.href = '?page=' + view;
    // window.location.reload(false);
  }
  function getXmlHttpRequestObject() {
      if (!xhr) {
          // Create a new XMLHttpRequest object 
          xhr = new XMLHttpRequest();
      }
      return xhr;
  };
  function login(event) {
    event.preventDefault();
    xhr = getXmlHttpRequestObject();
    xhr.onreadystatechange = loginCallback;
    // asynchronous requests
    var request = env.REACT_APP_HOST + "/login"
    xhr.open("POST", request, true);
    xhr.setRequestHeader("Content-Type", "application/json;charset=UTF-8");
    // Send the request over the network
    xhr.send(JSON.stringify({"username": username,  "password" : password}));
  }   
  function loginCallback() {
    // Check response is ready or not
    if (xhr.readyState == 4 && xhr.status == 201) {
        localStorage.setItem(env.REACT_APP_LOCAL_STORAGE_USERNAME, username)
        localStorage.setItem(env.REACT_APP_LOCAL_STORAGE_USER_TOKEN, xhr.responseText)
        changeView('Home')
        // window.location.reload(false);
    } 
    
  }
}





