import React, {useState} from 'react'
import './RegisterPage.css'
import env from "react-dotenv";
import PasswordStrengthBar from 'react-password-strength-bar';

export default function RegisterPage() {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [passwordConfirm, setPasswordConfirm] = useState('');
    const [error, setError] = useState('');
    var xhr = null;
    return(
        <div className="form">
            <div className="form-body">
                <div className="username">
                    <label className="form__label" htmlFor="username">Username</label>
                    <input className="form__input" type="text" id="username" placeholder="Username" onChange={event => setUsername(event.target.value)}/>
                </div>
                <div className="name">
                    <label className="form__label" htmlFor="name">Full Name </label>
                    <input  type="text" name="" id="name"  className="form__input"placeholder="Name" onChange={event => setName(event.target.value)}/>
                </div>
                <div className="email">
                    <label className="form__label" htmlFor="email">Email </label>
                    <input  type="email" id="email" className="form__input" placeholder="Email" onChange={event => setEmail(event.target.value)}/>
                </div>
                <div className="password">
                    <label className="form__label" htmlFor="password">Password </label>
                    <input className="form__input" type="password"  id="password" placeholder="Password" onChange={event => setPassword(event.target.value)}/>
                </div>
                <div className="confirm-password">
                    <label className="form__label" htmlFor="confirmPassword">Confirm Password </label>
                    
                    <input className="form__input" type="password" id="confirmPassword" placeholder="Confirm Password" onChange={event => setPasswordConfirm(event.target.value)}/>
                    {password.length > 0 ? <PasswordStrengthBar password={password} /> : null}
                </div>
                <p className="errorText">{error}</p>
            </div>
            <div className="footer">
                <button type="submit" className="btn btn-primary" onClick={() => register()}>Register</button>
            </div>
        </div>      
      )

    function isValidEmail(email) {
        return /\S+@\S+\.\S+/.test(email);
    }
    function register(){
        if(!isValidEmail(email)){
            setError("The email you have entered is not a valid email address.");
            return;
        }
        if(password !== passwordConfirm || password === ""){
            setError("Password does not match Confirm password. Re-enter your password.");
            return;
        }
        if(password.length < 6 ){
            setError("Password must be at least 6 characters");
            return;
        }
        if(name === ""){
            setError("You need to enter a name.");
            return;
        }
        if(username.length < 6){
            setError("Username must be at least 6 characters");
            return;
        }
        if(!usernameIsValid(username)){
            setError("Invalid username. Usernames can only contain the following characters: (A-Z a-z 0-9 .-). Try a different username.");
            return;
        }
        setError("");
        signUp();
    }

    function usernameIsValid(uname) {
        return /^[0-9a-zA-Z_.-]+$/.test(uname);
    }

    function getXmlHttpRequestObject() {
        if (!xhr) {
            // Create a new XMLHttpRequest object 
            xhr = new XMLHttpRequest();
        }
        return xhr;
    };
    function signUp() {
      xhr = getXmlHttpRequestObject();
      xhr.onreadystatechange = signUpCallback;
      // asynchronous requests
      var request = env.REACT_APP_HOST + "/createUser"
      xhr.open("POST", request, true);
      xhr.setRequestHeader("Content-Type", "application/json;charset=UTF-8");
      // Send the request over the network
      xhr.send(JSON.stringify({"username": username,  "password" : password, "email" : email, "realName" : name}));
    }   
    function signUpCallback() {
      // Check response is ready or not
      if (xhr.readyState == 4 && xhr.status == 201) {
          localStorage.setItem(env.REACT_APP_LOCAL_STORAGE_USERNAME, username)
          console.log(xhr.responseText)
          localStorage.setItem(env.REACT_APP_LOCAL_STORAGE_USER_TOKEN, xhr.responseText)
          sessionStorage.setItem(env.REACT_APP_LOCAL_STORAGE_CURRENT_VIEW, "Home");
          window.location.reload(false);
      }
      else if (xhr.readyState == 4){
        setError("Could not create user due to: " + xhr.responseText);
      }
    }
}
