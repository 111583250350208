import React, { useEffect} from 'react'
import './Menu.css';
import { Nav, Navbar, NavDropdown, NavItem } from "react-bootstrap";
import env from "react-dotenv";

import "bootstrap/dist/css/bootstrap.min.css";

export default function Menu() {

    
    //Try to login
    useEffect(() => {

    }, []);


  return ( 
    <Navbar collapseOnSelect expand="lg" bg="dark" variant="dark">
      <Navbar.Brand className="homeButton" onClick={() => changeView('Home')}>
      &nbsp;&nbsp;BSB
      </Navbar.Brand>
      <Navbar.Toggle aria-controls="responsive-navbar-nav" />
      <Navbar.Collapse id="responsive-navbar-nav">
        
        <Nav>
            <MenuItems /> 
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  )
  function loggedIn(){
      return localStorage.hasOwnProperty(env.REACT_APP_LOCAL_STORAGE_USERNAME) && localStorage.hasOwnProperty(env.REACT_APP_LOCAL_STORAGE_USER_TOKEN);
  }
  
  function logout(e){
      localStorage.removeItem(env.REACT_APP_LOCAL_STORAGE_USER_TOKEN);
      localStorage.removeItem(env.REACT_APP_LOCAL_STORAGE_USERNAME);
      console.log(sessionStorage.getItem(env.REACT_APP_LOCAL_STORAGE_CURRENT_VIEW))
      changeView('Home');
  }
  function changeView(view){
      sessionStorage.setItem(env.REACT_APP_LOCAL_STORAGE_CURRENT_VIEW, view);
      window.location.href = '?page=' + view;
    //   window.location.reload(false);
  }
  
  function MenuItems() {
      if(loggedIn()){
          return(
              <>
              <Nav.Link  onClick={() => changeView('Systems')}>&nbsp;Systems</Nav.Link>
              <Nav.Link  onClick={() => changeView('Profile')}>&nbsp;My Profile</Nav.Link>
              <Nav.Link  onClick={() => logout()}>&nbsp;Logout</Nav.Link>
          </>
          )
      } else {
          return(
              <>
                  <NavItem className="navbar-right">
                  &nbsp;<button className="loginButton" onClick={() => changeView('Login')}>Login / Register</button>
                  </NavItem>
              </>
          )
      }
  }
}

