import React, {useState, useEffect, useRef } from 'react'
import "bootstrap/dist/css/bootstrap.min.css"
import "./LoginPage.css"
import env from "react-dotenv";

export default function ChangePassword() {
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');

    var xhr = null;
  return (
    <div className="Auth-form-container">
      <form className="Auth-form" onSubmit={changePw}>
        <div className="Auth-form-content"> 
          <h3 className="Auth-form-title">Change Password</h3>
          <div className="form-group mt-3">
            <label>Old password</label>
            <input
              id="password"
              type="password"
              className="form-control mt-1"
              placeholder="Enter password"
              onChange={event => setPassword(event.target.value)}
              value={password}
            />
          </div>
          <div className="form-group mt-3">
            <label>New password</label>
            <input
              id="passwordNew"
              type="password"
              className="form-control mt-2"
              placeholder="New password"
              onChange={event => setNewPassword(event.target.value)}
              value={newPassword}
            />
          </div>
          <div className="form-group mt-3">
            <label>Confirm new password</label>
            <input
              id="passwordConfirm"
              type="password"
              className="form-control mt-3"
              placeholder="Confirm password"
              onChange={event => setConfirmPassword(event.target.value)}
              value={confirmPassword}
            />
          </div>
          <div className="d-grid gap-2 mt-3">
            <button type="submit" className="btn btn-primary">
              Submit
            </button>
          </div>
          <p className="errorText">{error}</p>
        </div>
      </form>
    </div>
  )
  function changeView(view){
    sessionStorage.setItem(env.REACT_APP_LOCAL_STORAGE_CURRENT_VIEW, view);
    window.location.href = '?page=' + view;
    // window.location.reload(false);
  }
  function getXmlHttpRequestObject() {
      if (!xhr) {
          // Create a new XMLHttpRequest object 
          xhr = new XMLHttpRequest();
      }
      return xhr;
  };
  function changePw(event) {
    event.preventDefault();
    if(newPassword !== confirmPassword || newPassword === ""){
        setError("Password does not match Confirm password. Re-enter your password.");
        return;
    }
    xhr = getXmlHttpRequestObject();
    xhr.onreadystatechange = changePwCallback;
    // asynchronous requests
    var request = env.REACT_APP_HOST + "/changePw"
    xhr.open("POST", request, true);
    xhr.setRequestHeader("Content-Type", "application/json;charset=UTF-8");
    // Send the request over the network
    xhr.send(JSON.stringify({"username": localStorage.getItem(env.REACT_APP_LOCAL_STORAGE_USERNAME),  "password" : password, "token" : localStorage.getItem(env.REACT_APP_LOCAL_STORAGE_USER_TOKEN), "newPassword" : newPassword}));
  }   
  function changePwCallback() {
    // Check response is ready or not
    if (xhr.readyState == 4 && xhr.status == 201) {
        localStorage.setItem(env.REACT_APP_LOCAL_STORAGE_USER_TOKEN, xhr.responseText)
        setError("New password has been set.");
        // window.location.reload(false);
    } 
    else if (xhr.readyState == 4){
        setError("Could not change password due to: " + xhr.responseText);
      }
    console.log(xhr.responseText)
  }
}
