import React, {useState} from 'react'
import './RegisterPage.css'
import env from "react-dotenv";
import PasswordStrengthBar from 'react-password-strength-bar';

export default function ResetPassword() {
    const [error, setError] = useState('');
    const [password, setPassword] = useState('');
    const [passwordConfirm, setPasswordConfirm] = useState('');
    var xhr = null;
    return(
        <div className="form">
            Enter new password
            <div className="form-body">
            <div className="password">
                <label className="form__label" htmlFor="password">Password </label>
                <input className="form__input" type="password"  id="password" placeholder="Password" onChange={event => setPassword(event.target.value)}/>
            </div>
            <div className="confirm-password">
                <label className="form__label" htmlFor="confirmPassword">Confirm Password </label>
                
                <input className="form__input" type="password" id="confirmPassword" placeholder="Confirm Password" onChange={event => setPasswordConfirm(event.target.value)}/>
                {password.length > 0 ? <PasswordStrengthBar password={password} /> : null}
            </div>
                <p className="errorText">{error}</p>
            </div>
            <div className="footer">
                <button type="submit" className="btn btn-primary" onClick={() => updatePassword()}>Reset password</button>
            </div>
        </div>      
      )

    function updatePassword(){
        if(password !== passwordConfirm || password === ""){
            setError("Password does not match Confirm password. Re-enter your password.");
            return;
        }
        if(password.length < 6 ){
            setError("Password must be at least 6 characters");
            return;
        }
        setError("");
        sendResetPassword();
    }

    function getXmlHttpRequestObject() {
        if (!xhr) {
            // Create a new XMLHttpRequest object 
            xhr = new XMLHttpRequest();
        }
        return xhr;
    };
    function sendResetPassword() {
      xhr = getXmlHttpRequestObject();
      xhr.onreadystatechange = resetPasswordCallback;
      // asynchronous requests
      var request = env.REACT_APP_HOST + "/resetPassword"
      xhr.open("POST", request, true);
      xhr.setRequestHeader("Content-Type", "application/json;charset=UTF-8");
      // Send the request over the network
      const queryParams = new URLSearchParams(window.location.search);
      const passwordToken = queryParams.get('resetPasswordToken');
      xhr.send(JSON.stringify({ "newPassword" : password, "passwordToken" : passwordToken}));
    }   
    function resetPasswordCallback() {
      // Check response is ready or not
      if (xhr.readyState == 4 && xhr.status == 201) {
          setError("Your password has been updated.");
          window.location.reload(false);
      }
      else if (xhr.readyState == 4){
        setError("Could not retreive password due to: " + xhr.responseText);
      }
    }
}
