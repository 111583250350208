import React, {useState, useEffect, useRef } from 'react'
import "bootstrap/dist/css/bootstrap.min.css"
import "./OpenSystem.css"
import "./EnterBid.css"
import "./previewBid.css"
import env from "react-dotenv";
import {decode} from 'html-entities';
import { InteractionManager } from 'react-native-web';
import { hasSelectionSupport } from '@testing-library/user-event/dist/utils'

export default function OpenSystem() {
    var xhr = null;
    var imgOpacityNoInfo = 0.6;
    const [timer, setTimer] = useState();
    const [placedBids, setPlacedBids] = useState([]);
    const [availBids, setAvailBids] = useState([]);
    const [systemName, setSystemName] = useState("SystemName");
    const [viewOption, setViewOption] = useState(false); //Set this in URL to remove changing options.
    const [systemId, setSystemId] = useState("-1");
    const [stared, setStared] = useState("uncheckedStar");
    const [bidName, setBidName] = useState("");
    const [opponentSystems, setOpponentSystems] = useState(["Standard"]);
    const [currentOpponent, setCurrentOpponent] = useState("Standard");
    const [zones, setZones] = useState("None");
    const [currentBid, setCurrentBid] = useState("PASS");
    const [currentBidSequence, setCurrentBidSequence] = useState("");
    const [enterBidVisible, setEnterBidVisible] = useState(false);
    const [previewUpperVisible, setPreviewUpperVisible] = useState(false);
    const [bidInfoText, setBidInfoText] = useState("");
    const [showBid, setShowBid] = useState([]);
    const [autoPass, setAutoPass] = useState(true);
    const [previewEnabled, setPreviewEnabled] = useState(false);
    const [newOpponents, setNewOpponents] = useState(false);
    const [favBids, setFavBids] = useState([["", ""]]);
    const [previewSequence, setPreviewSequence] = useState("");

    const cardsMinToMax = [0,1,2,3,4,5,6,7,8,9,10,11,12,13];
    const cardsMaxToMin = [13,12,11,10,9,8,7,6,5,4,3,2,1,0];
    const pointsMinToMax = [0,1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40];
    const pointsMaxToMin = [40,39,38,37,36,35,34,33,32,31,30,29,28,27,26,25,24,23,22,21,20,19,18,17,16,15,14,13,12,11,10,9,8,7,6,5,4,3,2,1,0];
    const [minSpades, setMinSpades] = useState(0);
    const [minHearts, setMinHearts] = useState(0);
    const [minDiamonds, setMinDiamonds] = useState(0);
    const [minClubs, setMinClubs] = useState(0);
    const [minPoints, setMinPoints] = useState(0);

    const [maxSpades, setMaxSpades] = useState(13);
    const [maxHearts, setMaxHearts] = useState(13);
    const [maxDiamonds, setMaxDiamonds] = useState(13);
    const [maxClubs, setMaxClubs] = useState(13);
    const [maxPoints, setMaxPoints] = useState(40);

    const [scrollPosition, setScrollPosition] = useState(0);
    const [mousePositionY, setMousePositionY] = useState(0);
    const [currentHoverBid, setCurrentHoverBid] = useState("PASS");
    var hoverBidInternal = "PASS";

    const changeMinSpades = event => {setMinSpades(event.target.value)};
    const changeMaxSpades = event => {setMaxSpades(event.target.value)};
    const changeMinHearts= event => {setMinHearts(event.target.value)};
    const changeMaxHearts = event => {setMaxHearts(event.target.value)};
    const changeMinDiamonds = event => {setMinDiamonds(event.target.value)};
    const changeMaxDiamonds = event => {setMaxDiamonds(event.target.value)};
    const changeMinClubs = event => {setMinClubs(event.target.value)};
    const changeMaxClubs = event => {setMaxClubs(event.target.value)};
    const changeMinPoints = event => {setMinPoints(event.target.value)};
    const changeMaxPoints = event => {setMaxPoints(event.target.value)};

    const changeFavBid = event => {showFavBid(event.target.value)};

    useEffect(() => { //Start
        const queryParams = new URLSearchParams(window.location.search);
        const SN = queryParams.get('systemName');
        const SID = queryParams.get('systemId');
        if(SN == null || SID == null){
            window.location.href = '?page=Home';
        }
        const viewer = queryParams.get('viewer');
        if(viewer){
            setViewOption(true);
        }
        setSystemName(SN);
        setSystemId(SID);
        // getOpponentSystems();
        hideBids("init");
        let previewStored = localStorage.getItem(env.REACT_APP_LOCAL_STORAGE_PREVIEW_ENABLED);
        if(previewStored !== null)
        {
            setPreviewEnabled(previewStored == "false" ? false : true);
        }
        getSpecialBids(SID);

        let seq = ""
        if(placedBids.length == 0){
            seq = zones + "_";
        } else {
            seq = zones + "_" + placedBids[placedBids.length-1].split(":")[2];
        }
        triggerGetAvailBids(SID, seq);
    }, [])

    useEffect(() => {
        const updateMousePosition = ev => {
          setMousePositionY(ev.clientY);
        };
        window.addEventListener('mousemove', updateMousePosition);
    }, []);

    useEffect(() => {
        const handleScroll = () => {
            const position = window.pageYOffset;
            setScrollPosition(position);
        };
        window.addEventListener('scroll', handleScroll, { passive: true });

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);
        
  return (
    <>
    {enterBidVisible ?
    <div className="enterBidBox" id="enterBidBox">
        <div className="enterBidBoxTop">
            <div className="currentBidPictureBox">
            <img className="exitButton" src={require("./bidBoxPics/" + getValueFromName(currentBid) + ".png")}/>
            </div>
            <div><b>Bid details</b></div>
            <div><img className="exitButton" src={require("./bidBoxPics/exitButton.png")} onClick={() => clearBids()}/></div>
        </div>
        <div className="enterBidBoxMid"> 
            <div className="enterNrOfCards">
                <div className="enterDetails">
                    <img className="enterBidPicture" src={require("./bidBoxPics/Spades.png")}/>
                    <select className="selectBox" disabled={viewOption} value={minSpades} onChange={changeMinSpades}>
                    {cardsMinToMax.map(item=>{
                        return <option defaultValue={item}>{item}</option>
                    })}
                    </select> 
                    -
                    <select className="selectBox" disabled={viewOption} value={maxSpades} onChange={changeMaxSpades}>
                    {cardsMaxToMin.map(item=>{
                        return <option defaultValue={item}>{item}</option>
                    })}
                    </select>
                </div>
                <div className="enterDetails">
                    <img className="enterBidPicture" src={require("./bidBoxPics/Hearts.png")}/>
                    <select className="selectBox" disabled={viewOption} value={minHearts} onChange={changeMinHearts}>
                    {cardsMinToMax.map(item=>{
                        return <option defaultValue={item}>{item}</option>
                    })}
                    </select> 
                    -
                    <select className="selectBox" disabled={viewOption} value={maxHearts} onChange={changeMaxHearts}>
                    {cardsMaxToMin.map(item=>{
                        return <option defaultValue={item}>{item}</option>
                    })}
                    </select>
                </div>
            </div>
            <div className="enterNrOfCards">
                <div className="enterDetails">
                    <img className="enterBidPicture" src={require("./bidBoxPics/Diamonds.png")}/>
                    <select className="selectBox" disabled={viewOption} value={minDiamonds} onChange={changeMinDiamonds}>
                    {cardsMinToMax.map(item=>{
                        return <option defaultValue={item}>{item}</option>
                    })}
                    </select> 
                    -
                    <select className="selectBox" disabled={viewOption} value={maxDiamonds} onChange={changeMaxDiamonds}>
                    {cardsMaxToMin.map(item=>{
                        return <option defaultValue={item}>{item}</option>
                    })}
                    </select>
                </div>
                <div className="enterDetails">
                    <img className="enterBidPicture" src={require("./bidBoxPics/Clubs.png")}/>
                    <select className="selectBox" disabled={viewOption} value={minClubs} onChange={changeMinClubs}>
                    {cardsMinToMax.map(item=>{
                        return <option defaultValue={item}>{item}</option>
                    })}
                    </select> 
                    -
                    <select className="selectBox" disabled={viewOption} value={maxClubs} onChange={changeMaxClubs}>
                    {cardsMaxToMin.map(item=>{
                        return <option defaultValue={item}>{item}</option>
                    })}
                    </select>
                </div>
            </div>
            <div className="enterPoints">
                Points: 
                    <select className="selectBoxPoints" disabled={viewOption} value={minPoints} onChange={changeMinPoints}>
                    {pointsMinToMax.map(item=>{
                        return <option defaultValue={item}>{item}</option>
                    })}
                    </select> 
                    ---
                    <select className="selectBoxPoints" disabled={viewOption} value={maxPoints} onChange={changeMaxPoints}>
                    {pointsMaxToMin.map(item=>{
                        return <option defaultValue={item}>{item}</option>
                    })}
                    </select>
            </div>
            <div className="enterInfo">
                
                <textarea className="bidInfoTextBox" disabled={viewOption} placeholder='No Bid Info Available' type="text" onChange={event => setBidInfoText(event.target.value)} value={bidInfoText}/>

                
            </div>
            <div className="favDiv">
                <img className="starButton" src={require("./bidBoxPics/" + stared + ".png") } onClick={() => toggleStared()}/>
                { stared == "checkedStar" ? 
                <input
                    id="favBid"
                    type="text"
                    // className="form-control"
                    placeholder="Enter bid name"
                    onChange={event => setBidName(event.target.value)}
                    value={bidName}
                />
                : null}
            </div>
        </div>
        <div className="enterBidBoxBot"> 
            <div>

            </div>
            <div>
            {!viewOption ?
            <button type="submit" className="btn btn-secondary saveButton" onClick={() => saveBid(false)}>
                Save bid
            </button>
            : null}
            { previewSequence == "" ?
            <button type="submit" className="btn btn-info saveButton" onClick={() => placeBid()}>
                Place bid
            </button>
            : null}
            {!viewOption ?
                <button type="submit" className="btn btn-success saveButton" onClick={() => saveBid(true)}>
                    Save and place bid
                </button>
            : null}
            </div>
            <div></div>
            <div> </div>
            
        </div>

    </div>
    : null}

{previewUpperVisible  && !enterBidVisible ?
    <div className="previewenterBidBoxUpper previewenterBidBox" style={{top: mousePositionY < 500 ? mousePositionY + 20 + scrollPosition : mousePositionY + 20 + scrollPosition}}>
        <div className="previewenterBidBoxTop"> 
            <img className="hoverBidImg" src={require("./bidBoxPics/" + getValueFromName(currentHoverBid) + ".png")}/>
        </div>
        <div className="previewenterBidBoxMid"> 
        
            <div className="previewenterNrOfCards">
                <div className="previewenterDetails">
                    <p className="pointsPrevievBox"><img className="enterBidPicture" src={require("./bidBoxPics/Spades.png")}/>: {minSpades} - {maxSpades}</p>
                </div>
                <div className="previewenterDetails">
                    <p className="pointsPrevievBox"><img className="enterBidPicture" src={require("./bidBoxPics/Hearts.png")}/>: {minHearts} - {maxHearts}</p>
                </div>
                <div className="previewenterDetails">
                    <p className="pointsPrevievBox"><img className="enterBidPicture" src={require("./bidBoxPics/Diamonds.png")}/>: {minDiamonds} - {maxDiamonds}</p>
                </div>
                <div className="previewenterDetails">
                    <p className="pointsPrevievBox"><img className="enterBidPicture" src={require("./bidBoxPics/Clubs.png")}/>: {minClubs} - {maxClubs}</p>
                    
                </div>
            </div>
            <div className="previewenterPoints">
                <p className="pointsPrevievBox">Points: {minPoints} - {maxPoints}</p>
                    
            </div>
            <div className="previewenterInfo">
                <p className="bidInfoPreview">{bidInfoText}</p>
            </div>
        </div>

    </div>
    : null}

    <div className="System-container">
      <div className="OpenSystemContent"> 
        {/* <h3 className="Title">Available Systems</h3> */}
        <div className="row rowTopBot" id="topRow">
            <div className="col-1"></div>
            <div className="col-10 horizontalBidTop" id="northBids">
                {placedBids.map(item=>{
                    if(parseInt(String(item).split(':')[0]) % 4 == 0){
                        return <img className="placedBidHorizontal" onMouseEnter={() => mouseEnterUpperSequence(String(item).split(':')[2])} onClick={() => editPreviewBid(String(item).split(':')[2])} onMouseLeave={() => mouseLeaveUpper()} src={require("./bidBoxPics/" + getValueFromName(String(item).split(':')[1]) + ".png")}/>
                    }
                })}
                <div className="northArrow"></div>
            </div>
            <div className="col-1"></div>
        </div>
        <div className="row rowMiddle" id="topRow">
            <div className="col-2 verticalBidEast" id="eastBids">
                {placedBids.map(item=>{
                    if(parseInt(String(item).split(':')[0]) % 4 == 3){
                        let img = getValueFromName(String(item).split(':')[1]);
                        return <img className="placedBidVertical placedBidVerticalEast"  onMouseEnter={() => mouseEnterUpperSequence(String(item).split(':')[2])} onMouseLeave={() => mouseLeaveUpper()}  onClick={() => editPreviewBid(String(item).split(':')[2])} src={require("./bidBoxPics/" + img + ".png")}/>
                    }
                })}
            </div>
            <div className="col-9 bidBox" id="bidBox"   style={
                {borderTop: placedBids.length % 4 == 0 ? "4px dashed gold" : "2px solid silver",
                borderRight: placedBids.length % 4 == 1 ? "4px dashed gold" : "2px solid silver",
                borderBottom: placedBids.length % 4 == 2 ? "4px dashed gold" : "2px solid silver",
                borderLeft: placedBids.length % 4 == 3 ? "4px dashed gold" : "2px solid silver"}
                }>
                    
                <div className="row bidBoxRow">
                    <div className="col-1 sideDiv"></div> 
                    <div className="col-10">
                        <div className="row rowShowDirection"></div> 
                        <div className="row bidContainer">
                            <div className="row bidRow">
                            {showBid[5] ? <img className="bidCol" id="1NT" onMouseEnter={() => mouseEnterUpper("1NT")} onMouseLeave={() => mouseLeaveUpper()} onClick={() => createBid("1NT")} src={require("./bidBoxPics/5.png")}/> : null}
                            {showBid[4] ? <img className="bidCol" id="1SP" onMouseEnter={() => mouseEnterUpper("1SP")} onMouseLeave={() => mouseLeaveUpper()} onClick={() => createBid("1SP")} src={require("./bidBoxPics/4.png")}/> : null}
                            {showBid[3] ? <img className="bidCol" id="1HE" onMouseEnter={() => mouseEnterUpper("1HE")} onMouseLeave={() => mouseLeaveUpper()} onClick={() => createBid("1HE")} src={require("./bidBoxPics/3.png")}/> : null}
                            {showBid[2] ? <img className="bidCol" id="1DI" onMouseEnter={() => mouseEnterUpper("1DI")} onMouseLeave={() => mouseLeaveUpper()} onClick={() => createBid("1DI")} src={require("./bidBoxPics/2.png")}/> : null}
                            {showBid[1] ? <img className="bidCol" id="1CL" onMouseEnter={() => mouseEnterUpper("1CL")} onMouseLeave={() => mouseLeaveUpper()} onClick={() => createBid("1CL")} src={require("./bidBoxPics/1.png")}/> : <img className="bidCol emptyBid" src={require("./bidBoxPics/emptyBid.png")}/>}
                            </div>
                            <div className="row bidRow">
                            {showBid[10] ? <img className="bidCol" id="2NT" onMouseEnter={() => mouseEnterUpper("2NT")} onMouseLeave={() => mouseLeaveUpper()} onClick={() => createBid("2NT")} src={require("./bidBoxPics/10.png")}/> : null}
                            {showBid[9] ? <img className="bidCol" id="2SP" onMouseEnter={() => mouseEnterUpper("2SP")} onMouseLeave={() => mouseLeaveUpper()} onClick={() => createBid("2SP")} src={require("./bidBoxPics/9.png")}/> : null}
                            {showBid[8] ? <img className="bidCol" id="2HE" onMouseEnter={() => mouseEnterUpper("2HE")} onMouseLeave={() => mouseLeaveUpper()} onClick={() => createBid("2HE")} src={require("./bidBoxPics/8.png")}/> : null}
                            {showBid[7] ? <img className="bidCol" id="2DI" onMouseEnter={() => mouseEnterUpper("2DI")} onMouseLeave={() => mouseLeaveUpper()} onClick={() => createBid("2DI")} src={require("./bidBoxPics/7.png")}/> : null}
                            {showBid[6] ? <img className="bidCol" id="2CL" onMouseEnter={() => mouseEnterUpper("2CL")} onMouseLeave={() => mouseLeaveUpper()} onClick={() => createBid("2CL")} src={require("./bidBoxPics/6.png")}/> : <img className="bidCol emptyBid" src={require("./bidBoxPics/emptyBid.png")}/>}
                            </div>
                            <div className="row bidRow">
                            {showBid[15] ? <img className="bidCol" id="3NT" onMouseEnter={() => mouseEnterUpper("3NT")} onMouseLeave={() => mouseLeaveUpper()} onClick={() => createBid("3NT")} src={require("./bidBoxPics/15.png")}/> : null}
                            {showBid[14] ? <img className="bidCol" id="3SP" onMouseEnter={() => mouseEnterUpper("3SP")} onMouseLeave={() => mouseLeaveUpper()} onClick={() => createBid("3SP")} src={require("./bidBoxPics/14.png")}/> : null}
                            {showBid[13] ? <img className="bidCol" id="3HE" onMouseEnter={() => mouseEnterUpper("3HE")} onMouseLeave={() => mouseLeaveUpper()} onClick={() => createBid("3HE")} src={require("./bidBoxPics/13.png")}/> : null}
                            {showBid[12] ? <img className="bidCol" id="3DI" onMouseEnter={() => mouseEnterUpper("3DI")} onMouseLeave={() => mouseLeaveUpper()} onClick={() => createBid("3DI")} src={require("./bidBoxPics/12.png")}/> : null}
                            {showBid[11] ? <img className="bidCol" id="3CL" onMouseEnter={() => mouseEnterUpper("3CL")} onMouseLeave={() => mouseLeaveUpper()} onClick={() => createBid("3CL")} src={require("./bidBoxPics/11.png")}/> : <img className="bidCol emptyBid" src={require("./bidBoxPics/emptyBid.png")}/>}
                            </div>
                            <div className="row bidRow">
                            {showBid[20] ? <img className="bidCol" id="4NT" onMouseEnter={() => mouseEnterUpper("4NT")} onMouseLeave={() => mouseLeaveUpper()} onClick={() => createBid("4NT")} src={require("./bidBoxPics/20.png")}/> : null}
                            {showBid[19] ? <img className="bidCol" id="4SP" onMouseEnter={() => mouseEnterUpper("4SP")} onMouseLeave={() => mouseLeaveUpper()} onClick={() => createBid("4SP")} src={require("./bidBoxPics/19.png")}/> : null}
                            {showBid[18] ? <img className="bidCol" id="4HE" onMouseEnter={() => mouseEnterUpper("4HE")} onMouseLeave={() => mouseLeaveUpper()} onClick={() => createBid("4HE")} src={require("./bidBoxPics/18.png")}/> : null}
                            {showBid[17] ? <img className="bidCol" id="4DI" onMouseEnter={() => mouseEnterUpper("4DI")} onMouseLeave={() => mouseLeaveUpper()} onClick={() => createBid("4DI")} src={require("./bidBoxPics/17.png")}/> : null}
                            {showBid[16] ? <img className="bidCol" id="4CL" onMouseEnter={() => mouseEnterUpper("4CL")} onMouseLeave={() => mouseLeaveUpper()} onClick={() => createBid("4CL")} src={require("./bidBoxPics/16.png")}/> : <img className="bidCol emptyBid" src={require("./bidBoxPics/emptyBid.png")}/>}
                            </div>
                            <div className="row bidRow">
                            {showBid[25] ? <img className="bidCol" id="5NT" onMouseEnter={() => mouseEnterUpper("5NT")} onMouseLeave={() => mouseLeaveUpper()} onClick={() => createBid("5NT")} src={require("./bidBoxPics/25.png")}/> : null}
                            {showBid[24] ? <img className="bidCol" id="5SP" onMouseEnter={() => mouseEnterUpper("5SP")} onMouseLeave={() => mouseLeaveUpper()} onClick={() => createBid("5SP")} src={require("./bidBoxPics/24.png")}/> : null}
                            {showBid[23] ? <img className="bidCol" id="5HE" onMouseEnter={() => mouseEnterUpper("5HE")} onMouseLeave={() => mouseLeaveUpper()} onClick={() => createBid("5HE")} src={require("./bidBoxPics/23.png")}/> : null}
                            {showBid[22] ? <img className="bidCol" id="5DI" onMouseEnter={() => mouseEnterUpper("5DI")} onMouseLeave={() => mouseLeaveUpper()} onClick={() => createBid("5DI")} src={require("./bidBoxPics/22.png")}/> : null}
                            {showBid[21] ? <img className="bidCol" id="5CL" onMouseEnter={() => mouseEnterUpper("5CL")} onMouseLeave={() => mouseLeaveUpper()} onClick={() => createBid("5CL")} src={require("./bidBoxPics/21.png")}/> : <img className="bidCol emptyBid" src={require("./bidBoxPics/emptyBid.png")}/>}
                            </div>
                            <div className="row bidRow">
                            {showBid[30] ? <img className="bidCol" id="6NT" onMouseEnter={() => mouseEnterUpper("6NT")} onMouseLeave={() => mouseLeaveUpper()} onClick={() => createBid("6NT")} src={require("./bidBoxPics/30.png")}/> : null}
                            {showBid[29] ? <img className="bidCol" id="6SP" onMouseEnter={() => mouseEnterUpper("6SP")} onMouseLeave={() => mouseLeaveUpper()} onClick={() => createBid("6SP")} src={require("./bidBoxPics/29.png")}/> : null}
                            {showBid[28] ? <img className="bidCol" id="6HE" onMouseEnter={() => mouseEnterUpper("6HE")} onMouseLeave={() => mouseLeaveUpper()} onClick={() => createBid("6HE")} src={require("./bidBoxPics/28.png")}/> : null}
                            {showBid[27] ? <img className="bidCol" id="6DI" onMouseEnter={() => mouseEnterUpper("6DI")} onMouseLeave={() => mouseLeaveUpper()} onClick={() => createBid("6DI")} src={require("./bidBoxPics/27.png")}/> : null}
                            {showBid[26] ? <img className="bidCol" id="6CL" onMouseEnter={() => mouseEnterUpper("6CL")} onMouseLeave={() => mouseLeaveUpper()} onClick={() => createBid("6CL")} src={require("./bidBoxPics/26.png")}/> : <img className="bidCol emptyBid" src={require("./bidBoxPics/emptyBid.png")}/>}
                            </div>
                            <div className="row bidRow">
                            {showBid[35] ? <img className="bidCol" id="7NT" onMouseEnter={() => mouseEnterUpper("7NT")} onMouseLeave={() => mouseLeaveUpper()} onClick={() => createBid("7NT")} src={require("./bidBoxPics/35.png")}/> : null}
                            {showBid[34] ? <img className="bidCol" id="7SP" onMouseEnter={() => mouseEnterUpper("7SP")} onMouseLeave={() => mouseLeaveUpper()} onClick={() => createBid("7SP")} src={require("./bidBoxPics/34.png")}/> : null}
                            {showBid[33] ? <img className="bidCol" id="7HE" onMouseEnter={() => mouseEnterUpper("7HE")} onMouseLeave={() => mouseLeaveUpper()} onClick={() => createBid("7HE")} src={require("./bidBoxPics/33.png")}/> : null}
                            {showBid[32] ? <img className="bidCol" id="7DI" onMouseEnter={() => mouseEnterUpper("7DI")} onMouseLeave={() => mouseLeaveUpper()} onClick={() => createBid("7DI")} src={require("./bidBoxPics/32.png")}/> : null}
                            {showBid[31] ? <img className="bidCol" id="7CL" onMouseEnter={() => mouseEnterUpper("7CL")} onMouseLeave={() => mouseLeaveUpper()} onClick={() => createBid("7CL")} src={require("./bidBoxPics/31.png")}/> : <img className="bidCol emptyBid" src={require("./bidBoxPics/emptyBid.png")}/>}
                            </div>
                            <div className="row bidRow">
                            <img className="bidColLastP" id="PASS" onMouseEnter={() => mouseEnterUpper("PASS")} onMouseLeave={() => mouseLeaveUpper()} onClick={() => createBid("PASS")} src={require("./bidBoxPics/PASS.png")}/>
                            <img className="bidColLastX" id="X" onMouseEnter={() => mouseEnterUpper("X")} onMouseLeave={() => mouseLeaveUpper()} onClick={() => createBid("X")} src={require("./bidBoxPics/X.png")}/>
                            <img className="bidColLastX" id="XX" onMouseEnter={() => mouseEnterUpper("XX")} onMouseLeave={() => mouseLeaveUpper()} onClick={() => createBid("XX")} src={require("./bidBoxPics/XX.png")}/>
                            </div>
                        </div>
                        <div className="row rowShowDirection"></div> 
                    </div>
                    <div className="col-1 sideDiv"></div>
                </div>
            </div>
            <div className="col-2 verticalBidWest" id="westBids">
                {placedBids.map(item=>{
                    if(parseInt(String(item).split(':')[0]) % 4 == 1){
                        let img = getValueFromName(String(item).split(':')[1]);
                        return <img className="placedBidVertical placedBidVerticalWest"  onMouseEnter={() => mouseEnterUpperSequence(String(item).split(':')[2])} onMouseLeave={() => mouseLeaveUpper()}  onClick={() => editPreviewBid(String(item).split(':')[2])} src={require("./bidBoxPics/" + img + ".png")}/>
                    }
                })}
            </div>
        </div>
        <div className="row rowTopBot" id="topRow"> 
            <div className="col-1">
                {placedBids.length > 0 ?<img className="backArrow" onClick={() => goBackOneBid()} src={require("./bidBoxPics/undoArrow.png")}/>: null}
            </div>
            <div className="col-10 horizontalBidBot" id="SouthBids">
                {placedBids.map(item=>{
                    if(parseInt(String(item).split(':')[0]) % 4 == 2){
                        return <img className="placedBidHorizontal"  onMouseEnter={() => mouseEnterUpperSequence(String(item).split(':')[2])} onMouseLeave={() => mouseLeaveUpper()}  onClick={() => editPreviewBid(String(item).split(':')[2])} src={require("./bidBoxPics/" + getValueFromName(String(item).split(':')[1]) + ".png")}/>
                    }
                })}
            </div>
            <div className="col-1"></div>
        </div>
        <div className="row advancedOptions">
            <h5 className="changingFonts settingsHeader">{systemName} <p className='adSet'>(Settings)</p></h5>
            {/* <div className="row advancedRow"> */}
                <div className="col-4 advancedCol">
                <label className="specialBidsText">Jump to star bid:</label> 
                <select className="form-select w-2 specialBids" type="text" list="favBids" placeholder='select bid' onChange={changeFavBid}>
                    {favBids.map(item => {
                        return (<option key={item[1]} value={item[1]}>{item[0]}</option>);
                    })}
                </select>
                </div>
                <div className='col-4 advancedCol'>
                        <p className="changingFonts">Auto pass opponents: </p>
                        <form>
                            <input
                                className="CheckAutoPass"
                                type="checkbox"
                                checked={autoPass}
                                onChange={e => setAutoPass(e.target.checked)}
                            />
                        </form>
                        <p className="changingFonts">Preview enabled:</p>
                        <form>
                            <input
                                className="CheckAutoPass"
                                type="checkbox"
                                checked={previewEnabled}
                                onClick={e => togglePreviewEnabled(e.target.checked)}
                            />
                        </form>
                </div>
                <div className='col-2 advancedCol'>
                <label className="changingFonts zonesText">Zones</label><br/>
                    <img className="zoneImage" onClick={() => swapZone()} src={require("./bidBoxPics/" + zones + ".png")}/>
                </div>
            {/* </div> */}
        </div>
      </div>
  </div> 
  </>
  )

  function toggleStared(){
    if(viewOption){
        return;
    }
    if(stared == "uncheckedStar"){
        setStared("checkedStar");
    } else {
        setStared("uncheckedStar");
    }
    setBidName("");
  }

  function togglePreviewEnabled(val){
    setPreviewEnabled(val);
    localStorage.setItem(env.REACT_APP_LOCAL_STORAGE_PREVIEW_ENABLED, val);
  }

  function mouseEnterUpperSequence(item){
    let sequence = zones + "_" + item;
    let allBids = String(item).split('.');
    let lastBid = allBids[allBids.length-1];
    setCurrentHoverBid(lastBid);
    hoverBidInternal = lastBid;
    setTimer(setTimeout(() => {
        if(hoverBidInternal == lastBid && !enterBidVisible){
            // getBidPreview(bid)
            //Chill and then get bid.
            getBidInfo(sequence, true)
            setPreviewUpperVisible(true);
        }
        
      }, 500));
  }

  function mouseEnterUpper(bid){
    setCurrentHoverBid(bid);
    hoverBidInternal = bid;
    setTimer(setTimeout(() => {
        if(hoverBidInternal == bid  && !enterBidVisible && previewEnabled && availBids.includes(bid)){
            getBidPreview(bid)
            //Chill and then get bid.
            setPreviewUpperVisible(true);
        }
        
      }, 500));

    
  }
  function mouseLeaveUpper(){
    clearTimeout(timer);
    hoverBidInternal = "";
    setPreviewUpperVisible(false);
  }

  function getBidPreview(bid){
    var sequence = getSequence(bid);
    getBidInfo(sequence, true)
  }

  function saveBid(placeBid){
    
    sendBidInfo(currentBid, placeBid);
    setPreviewSequence("");
    setEnterBidVisible(false);
  }

  function changeZoneChangeBids(newZone){
    let seq = "";
    if(placedBids.length == 0){
        seq = newZone + "_";
    } else {
        seq = newZone + "_" + placedBids[placedBids.length-1].split(":")[2];
    }
    triggerGetAvailBids(systemId, seq);
  }

    function placeBid(){
        let lastPlacedBid = "";
        if(autoPass){
            lastPlacedBid = getSequence(currentBid) + ".PASS"
        } else {
            lastPlacedBid = getSequence(currentBid)
        }
        setCurrentBidSequence(lastPlacedBid)
        
        hideBids(currentBid)
        var bidString = ""
        var bidInfo = ""
        if(placedBids.length > 0){
            bidInfo =  ":" + String(placedBids[placedBids.length-1]).split(":")[2] + "." + currentBid;
            bidString = (placedBids.length) + ":" + currentBid  + bidInfo;
        }
        else{
            bidInfo = ":"  + currentBid;
            bidString = "0" + ":" + currentBid  + bidInfo;
        }
        let bidsArray = placedBids;
        placedBids.push(bidString);
        if(autoPass){
            let passBid = (placedBids.length) + ":PASS" + bidInfo + ".PASS";
            placedBids.push(passBid);
            
        }
        
        setPlacedBids(bidsArray);
        clearBids();
        getSpecialBids(systemId);
        
        
        let seq = "";
        if(placedBids.length == 0){
            seq = zones + "_";
        } else {
            seq = zones + "_" + placedBids[placedBids.length-1].split(":")[2];
        }
        triggerGetAvailBids(systemId, seq);
  }

  function triggerGetAvailBids(sysId, seq){
    let bids = document.getElementsByClassName("bidCol");
    for(let i = 0; i < bids.length ; ++i){
        bids[i].style.opacity = imgOpacityNoInfo;
    }
    bids = document.getElementsByClassName("bidColLastX");
    for(let i = 0; i < bids.length ; ++i){
        bids[i].style.opacity = imgOpacityNoInfo;
    }
    bids = document.getElementsByClassName("bidColLastP");
    for(let i = 0; i < bids.length ; ++i){
        bids[i].style.opacity = imgOpacityNoInfo;
    }
    setTimeout(() => {
        getAllAvailBids(seq, sysId)
      }, 100);
  }

  function getAllAvailBids(sequence, sysId){
    xhr = getXmlHttpRequestObject();
    xhr.onreadystatechange = getAvailBidsCallback;
    var url = env.REACT_APP_HOST + "/getAllAvailBids?username=" +  localStorage.getItem(env.REACT_APP_LOCAL_STORAGE_USERNAME) 
                                 + "&token=" + localStorage.getItem(env.REACT_APP_LOCAL_STORAGE_USER_TOKEN)
                                 + "&systemId=" + sysId
                                 + "&currentBid=" + sequence;
    xhr.open("GET", url, true);
    // Send the request over the network
    xhr.send(null);
  }
  function getAvailBidsCallback() {
    if (xhr.readyState == 4 && xhr.status == 200) {
        var response = JSON.parse(xhr.responseText);
        let bids = [];
        for(let i = 0; i < response.length; ++i){
            let bid = ( response[i].split("_").length > 1 ? response[i].split("_")[1] : response[i]);
            let img = document.getElementById(bid);
            bids.push(bid);
            img.style.opacity = 1;
        }
        setAvailBids(bids);
    }
}









//   function saveAndPlaceBid(){
//       saveBid();
//       placeBid();
//   }
function hideBids(bid){
    var value = getValueFromName(bid);
    if(bid != "P" && bid != "X" && bid != "XX" && bid != "PASS" && bid){
        var newShowBids = [];
        for(var i = 0; i < 36; ++i){
            if(i <= value)
            {
                newShowBids.push(false)
            }
            else{
                newShowBids.push(true)
            }
        }
        setShowBid(newShowBids);
    }
}

  function clearBids(){
    setMinSpades(0);
    setMinHearts(0);
    setMinDiamonds(0);
    setMinClubs(0);
    setMinPoints(0);
    setMaxSpades(13);
    setMaxHearts(13);
    setMaxDiamonds(13);
    setMaxClubs(13);
    setMaxPoints(40);
    setBidInfoText("");
    setEnterBidVisible(false);
    setPreviewSequence("");
    setStared("uncheckedStar");
    setBidName("");
  }

  function createBid(bid){
    setCurrentBid(bid)
    var sequence = getSequence(bid);
    getBidInfo(sequence, false)
    setEnterBidVisible(true)
  }
  function getSequence(bid){
    var sequence = "";
    if(placedBids.length > 0){
        sequence = zones + "_" + placedBids[placedBids.length-1].split(":")[2] + "." + bid;
    }else{
        sequence = zones + "_" +  bid;
    }
    return sequence;
  }
  function getXmlHttpRequestObject() {
    if (!xhr) {
        // Create a new XMLHttpRequest object 
        xhr = new XMLHttpRequest();
    }
    return xhr;
    };


  function getLastBidPlacedByUser(lastSequence){
    let zones = lastSequence.split("_")[0];
    let bidSequence = lastSequence.split("_")[1];
    let bids = bidSequence.split(".");
    let newSequence = zones + "_";
    for(let i = 0; i < bids.length - 4; ++i){
        newSequence = newSequence + bids[i];
        if(i != bids.length - 5){
            newSequence = newSequence + ".";
        }
    }
    getBidInfo(newSequence, false, true);
  }
  function getBidInfo(sequence, isPreview, excludeText) {
    xhr = getXmlHttpRequestObject();
    xhr.onreadystatechange = getBidInfoCallback;
    if(isPreview){
        xhr.onreadystatechange = getBidInfoCallbackPreview;
    } if(excludeText){
        xhr.onreadystatechange = getBidInfoCallbackExcludeText
    }
    var url = env.REACT_APP_HOST + "/getBid?username=" +  localStorage.getItem(env.REACT_APP_LOCAL_STORAGE_USERNAME) 
                                 + "&token=" + localStorage.getItem(env.REACT_APP_LOCAL_STORAGE_USER_TOKEN)
                                 + "&systemId=" + systemId
                                 + "&opponentSystemId=" + currentOpponent
                                 + "&sequence=" + sequence;
    xhr.open("GET", url, true);
    // Send the request over the network
    xhr.send(null);
  }

  function resetBidInfo(){
    setMinSpades(0);
    setMaxSpades(13);
    setMinHearts(0);
    setMaxHearts(13);
    setMinDiamonds(0);
    setMaxDiamonds(13);
    setMinClubs(0);
    setMaxClubs(13);
    setMinPoints(11);
    setMaxPoints(20);
    setBidInfoText("");
    setStared("uncheckedStar");
    setBidName("");
  }

  function setBidInfoXhrResponse(xhr, excludeText){
    var response = JSON.parse(xhr.responseText)
    let bidInfoReceived = response[0]
    let bidName = response[2];
    var info = String(bidInfoReceived).split("_");
    setMinSpades(info[1].split("-")[0]);
    setMaxSpades(info[1].split("-")[1]);
    setMinHearts(info[2].split("-")[0]);
    setMaxHearts(info[2].split("-")[1]);
    setMinDiamonds(info[3].split("-")[0]);
    setMaxDiamonds(info[3].split("-")[1]);
    setMinClubs(info[4].split("-")[0]);
    setMaxClubs(info[4].split("-")[1]);
    setMinPoints(info[5].split("-")[0]);
    setMaxPoints(info[5].split("-")[1]);
    setBidInfoText(excludeText ? "" : decodeString(info[0]).replace("/underscore/", "_"));

    if(bidName != null && bidName != ""){
        setStared(excludeText ? "uncheckedStar" : "checkedStar");
        setBidName(excludeText ? "" : bidName);
    }
  }
  
  function getBidInfoCallbackPreview() {
    if (xhr.readyState == 4 && xhr.status == 200) {
        if(String(xhr.responseText).includes("Invalid: No bid info available")){
            resetBidInfo();
        }
        else{
            setBidInfoXhrResponse(xhr, false);
        }
    }
}
function getBidInfoCallbackExcludeText() {
    // Check response is ready or not
    if (xhr.readyState == 4 && xhr.status == 200) {
        if(String(xhr.responseText).includes("Invalid: No Bid Info Available")){
            var response = JSON.parse(xhr.responseText)
            let lastSequence = response[1]
            if(lastSequence.split(".").length > 4){
                getLastBidPlacedByUser(lastSequence);
            }
            resetBidInfo();
        }
        else{
            setBidInfoXhrResponse(xhr, true);
        }
    }
}
  
  function getBidInfoCallback() {
    // Check response is ready or not
    if (xhr.readyState == 4 && xhr.status == 200) {
        if(String(xhr.responseText).includes("Invalid: No Bid Info Available")){
            var response = JSON.parse(xhr.responseText)
            let lastSequence = response[1]
            if(lastSequence.split(".").length > 4){
                getLastBidPlacedByUser(lastSequence);
            }
            resetBidInfo();
        }
        else{
            setBidInfoXhrResponse(xhr, false);
        }
    }
}

function decodeString(s){
    return s.replace(/\\u([0-9a-fA-F]{4})/g, (m,cc)=>String.fromCharCode("0x"+cc)).split('\\n').join(' ');;
}

function sendBidInfo(bid, placeBid) {
    xhr = getXmlHttpRequestObject();
    xhr.onreadystatechange = placeBid ? sendBidInfoPlaceBidCallback : sendBidInfoCallback;
    // asynchronous requests
    var request = env.REACT_APP_HOST + "/addBid"
    xhr.open("POST", request, true);
    xhr.setRequestHeader("Content-Type", "application/json;charset=UTF-16");
    xhr.setRequestHeader("Content-Type", "application/x-www-form-urlencoded");
    xhr.setRequestHeader("Content-Type", "text/xml; charset=iso-8859-9"); 
    // Send the request over the network
    let bidInfoTextToSend = bidInfoText.replace("_", "/underscore/")
    let usedSequence = getSequence(bid);
    if(previewSequence != ""){
        usedSequence = previewSequence;
    }
    xhr.send(JSON.stringify({"username": localStorage.getItem(env.REACT_APP_LOCAL_STORAGE_USERNAME) 
                            ,"token" : localStorage.getItem(env.REACT_APP_LOCAL_STORAGE_USER_TOKEN)
                            ,"sequence" : usedSequence
                            ,"bidInfo" : (bidInfoTextToSend + "_" 
                                         + minSpades + "-" + maxSpades + "_"
                                         + minHearts + "-" + maxHearts + "_"
                                         + minDiamonds + "-" + maxDiamonds + "_"
                                         + minClubs + "-" + maxClubs + "_"
                                         + minPoints + "-" + maxPoints)
                            , "opponentSystemId" : currentOpponent
                            , "systemId" : systemId
                            , "bidName" : bidName
                        }));
  }   
  function sendBidInfoCallback() {
    // Check response is ready or not
    if (xhr.readyState == 4 && xhr.status == 201) {
        clearBids();
        let seq = "";
        if(placedBids.length == 0){
            seq = zones + "_";
        } else {
            seq = zones + "_" + placedBids[placedBids.length-1].split(":")[2];
        }
        triggerGetAvailBids(systemId, seq);
    } 
  }
  function sendBidInfoPlaceBidCallback() {
    // Check response is ready or not
    if (xhr.readyState == 4 && xhr.status == 201) {
        placeBid();
        let seq = "";
        if(placedBids.length == 0){
            seq = zones + "_";
        } else {
            seq = zones + "_" + placedBids[placedBids.length-1].split(":")[2];
        }
        triggerGetAvailBids(systemId, seq);
    } 
  }

  function goBackOneBid(){
    var tmpBids = [];
    let removeNr = 1;
    if(autoPass){
        removeNr = 2;
    }
    for(var i = 0; i < placedBids.length-removeNr; ++i){
        if(i == placedBids.length-removeNr-1){
            hideBids(getLatestBid(tmpBids));
        }
        tmpBids.push(placedBids[i]);
    }
    setPlacedBids(tmpBids);
    if(tmpBids.length == 0){
        hideBids("init");
    }
    else{
        hideBids(getLatestBid(tmpBids));
    }

    let seq = "";
    if(tmpBids.length == 0){
        seq = zones + "_";
    } else {
        seq = zones + "_" + tmpBids[tmpBids.length-1].split(":")[2];
    }
    triggerGetAvailBids(systemId, seq);
  }

function getLatestBid(tmpBids){
    if(tmpBids.length > 0){
        for(var i = tmpBids.length; i >= 0; --i){
            if(tmpBids[tmpBids.length-1].split(":")[1] !== "PASS" && tmpBids[tmpBids.length-1].split(":")[1] !== "X" && tmpBids[tmpBids.length-1].split(":")[1] !== "XX"){
                
                return tmpBids[tmpBids.length-1].split(":")[1];
            }
        }
    }
    return "";
}

  function swapZone(){
    switch(zones){
        case "None": setZones("NS");
                     changeZoneChangeBids("NS");
                     break;
        case "NS": setZones("EW");
                    changeZoneChangeBids("EW");
                    break;
        case "EW": setZones("All");
                    changeZoneChangeBids("All");
                    break;
        case "All": setZones("None");
                    changeZoneChangeBids("None");
                    break;
    }
    
  }
  function getValueFromName(name){
    switch(name){
        case "1NT": return 5;
        case "2NT": return 10;
        case "3NT": return 15;
        case "4NT": return 20;
        case "5NT": return 25;
        case "6NT": return 30;
        case "7NT": return 35;

        case "1SP": return 4;
        case "2SP": return 9;
        case "3SP": return 14;
        case "4SP": return 19;
        case "5SP": return 24;
        case "6SP": return 29;
        case "7SP": return 34;

        case "1HE": return 3;
        case "2HE": return 8;
        case "3HE": return 13;
        case "4HE": return 18;
        case "5HE": return 23;
        case "6HE": return 28;
        case "7HE": return 33;

        case "1DI": return 2;
        case "2DI": return 7;
        case "3DI": return 12;
        case "4DI": return 17;
        case "5DI": return 22;
        case "6DI": return 27;
        case "7DI": return 32;

        case "1CL": return 1;
        case "2CL": return 6;
        case "3CL": return 11;
        case "4CL": return 16;
        case "5CL": return 21;
        case "6CL": return 26;
        case "7CL": return 31;

        case "PASS": return "P";
        case "X": return "X";
        case "XX": return "XX";
    }
    return 0;
  }


  function getSpecialBids(sysId) {
    xhr = getXmlHttpRequestObject();
    xhr.onreadystatechange = getSpecialBidsCallback;
    
    var url = env.REACT_APP_HOST + "/getAllFavBids?username=" +  localStorage.getItem(env.REACT_APP_LOCAL_STORAGE_USERNAME) 
                                 + "&token=" + localStorage.getItem(env.REACT_APP_LOCAL_STORAGE_USER_TOKEN)
                                 + "&systemId=" + sysId;
    xhr.open("GET", url, true);
    // Send the request over the network
    xhr.send(null);
  }
  function getSpecialBidsCallback() {
    if (xhr.readyState == 4 && xhr.status == 200) {
        var response = JSON.parse(xhr.responseText);
        let bids = [[]];
        for(let i = 0; i < response.length; ++i){
            bids.push([response[i][3], response[i][2]]);
        }
        setFavBids(bids);
    }
}
    function showFavBid(bidSequence){
        if(bidSequence == ""){
            hideBids("init");
            setPlacedBids([]);
            return;
        }
        //Change zone
        let zone = bidSequence.split("_")[0];
        setZones(zone);
        let bids = bidSequence.split("_")[1].split(".");
        setCurrentBid(bids[bids.length-1]);
        let pBids = [];
        let pBidSequence = "";
        for(let i = 0; i < bids.length-1; ++i){
            if(i > 0)
            {
                pBidSequence = pBidSequence + "." + bids[i];
            } else {
                pBidSequence = bids[i];
            }
            let saveThisIntoPlacedBids = i + ":" + bids[i] + ":" + pBidSequence;
            pBids.push(saveThisIntoPlacedBids);
            hideBids(getLatestBid(pBids));
            // #### Removed due to showing bid instead of just placing.
            // if(autoPass){
            //     if(i == bids.length-1){
            //         hideBids(getLatestBid(pBids));
            //         pBids.push((i+1) + ":PASS:" + pBidSequence + ".PASS");
            //     } else {
                    
            //     }
            // }
        }
        var lastSequence = "";
        if(pBids.length > 0){
            lastSequence = zones + "_" + pBids[pBids.length-1].split(":")[2] + "." + bids[bids.length-1];
        }else{
            lastSequence = zones + "_" +  bids[bids.length-1];
        }
        getBidInfo(lastSequence, false, false);
        setEnterBidVisible(true);
        setPlacedBids(pBids);
    }

    function editPreviewBid(bidSemiSequence){
        let sequence = zones + "_" + bidSemiSequence;
        getBidInfo(sequence, false, false);
        setEnterBidVisible(true);
        let showThisBidIcon = bidSemiSequence.split(".")[bidSemiSequence.split(".").length-1];
        setCurrentBid(showThisBidIcon)
        setPreviewSequence(sequence);
    }
}
