import React, { useState, useEffect } from 'react';
import { FlatList, StyleSheet, Text, View } from 'react-native';
import './ListSystems.css';
import env from 'react-dotenv';

const styles = StyleSheet.create({
  container: {
    flex: 1,
    paddingTop: 22,
  },
  item: {
    backgroundColor: 'lightgray',
    padding: 10,
    margin: 10,
    fontSize: 18,
    height: 70,
    cursor: 'pointer',
  },
});

export default function ListSystems() {
  const [systemAsOwner, setSystemAsOwner] = useState([]);
  const [systemAsEditor, setSystemAsEditor] = useState([]);
  const [enterNewSystemVisible, setEnterNewSystemVisible] = useState(false);
  const [deleteSystemVisible, setDeleteSystemVisible] = useState(false);
  const [deleteSystemEditorVisible, setDeleteSystemEditorVisible] = useState(false);
  const [settingsVisible, setsettingsVisible] = useState(false);
  const [newSystemName, setNewSystemName] = useState('');
  const [deleteSystemName, setDeleteSystemName] = useState('');
  const [settingsSystemName, setSettingsSystemName] = useState('');
  const [deleteSystemId, setDeleteSystemId] = useState('');
  const [createError, setCreateError] = useState('');
  const [deleteError, setDeleteError] = useState('');
  const [password, setPassword] = useState('');
  const [toBeRemoved, setToBeRemoved] = useState();
  const [systemEditors, setSystemEditors] = useState([]);
  const [editorInFocus, setEditorInFocus] = useState('');
  const [settingsSystemId, setSettingsSystemId] = useState(-1);
  const [users, setUsers] = useState([]);
  const [searchingUsers, setSearchingUsers] = useState([]);
  const [currentUserToInvite, setCurrentUserToInvite] = useState('');
  const [newSystemNameChange, setNewSystemNameChange] = useState('');

  const changeEditorInFocus = event => { setEditorInFocus(event.target.value) };

  useEffect(() => {
    setSystemAsOwner([]);
    setSystemAsEditor([]);
    getSystemsAsOwner();
  }, []);
  
  var xhr = null;

  return (
    <>
      {enterNewSystemVisible ?
        <div className="newSystemBox" id="newSystemBox">
          <div className="newSystemBoxTop">
            <div></div>
            <div><b>New System</b></div>
            <div><img className="exitButton" src={require("./bidBoxPics/exitButton.png")} onClick={() => setEnterNewSystemVisible(false)} /></div>
          </div>
          <div className="newSystemBoxMid">
            <label>Name: </label>
            <input className="newSystemNameTextBox" type="text" onChange={event => setNewSystemName(event.target.value)} value={newSystemName} />
            <button type="submit" className="btn btn-primary createButton" onClick={() => createSystem()}>
              Create
            </button>
            <p className="errorText">{createError}</p>
          </div>
        </div>
        : null}

      {deleteSystemVisible ?
        <div className="deleteSystemBox" id="deleteSystemBox">
          <div className="deleteSystemBoxTop">
            <div></div>
            <div><b>Delete System</b></div>
            <div><img className="exitButton" src={require("./bidBoxPics/exitButton.png")} onClick={() => setDeleteSystemVisible(false)} /></div>
          </div>
          <div className="newSystemBoxMid">
            <label>Confirm password to delete {deleteSystemName}: </label>
            <input
              id="password"
              type="password"
              className="form-control mt-1"
              placeholder="Enter password"
              onChange={event => setPassword(event.target.value)}
              value={password}
            />
            <button type="submit" className="btn btn-warning createButton" onClick={() => deleteSystem()}>
              Delete
            </button>
            <p className="errorText">{deleteError}</p>
          </div>
        </div>
        : null}

      {deleteSystemEditorVisible ?
        <div className="deleteSystemBox" id="deleteSystemBox">
          <div className="deleteSystemBoxTop">
            <div></div>
            <div><b>Delete System</b></div>
            <div><img className="exitButton" src={require("./bidBoxPics/exitButton.png")} onClick={() => setDeleteSystemEditorVisible(false)} /></div>
          </div>
          <div className="newSystemBoxMid">
            <label>Confirm password to remove yourself as editor from {deleteSystemName}: </label>
            <input
              id="password"
              type="password"
              className="form-control mt-1"
              placeholder="Enter password"
              onChange={event => setPassword(event.target.value)}
              value={password}
            />
            <button type="submit" className="btn btn-warning createButton" onClick={() => removeMyselfAsEditor()}>
              Delete
            </button>
            <p className="errorText">{deleteError}</p>
          </div>
        </div>
        : null}

      <div className="Systems-container">
        <div className="Systems">
          <div className="ListSystemContent">
            <div className="TitleAndButton">
              <div></div>
              <div>
                <h3 className="Title">Available Systems</h3>
              </div>
              <div>
                <img className="addButton" src={require("./bidBoxPics/addButton.png")} onClick={() => setEnterNewSystemVisible(true)} />
              </div>
            </div>
            <div>
              <table className="fl-table">
                <th className='tableNames'></th>
                <th className='tableIcons'></th>
                <th className='tableIcons'></th>
                {systemAsOwner.map(item => {
                  return (
                    <tr>
                      <td className='systemNameColumnText' onClick={() => openSystem(String(item.key).split(':')[0], String(item.key).split(':')[1])}>{String(item.key).split(':')[1].length < 17 ? String(item.key).split(':')[1] : String(item.key).split(':')[1].slice(0, 15) + ".."}</td>
                      <td><img className="iconButton" src={require("./bidBoxPics/settingWheel.png")} onClick={() => showSystemSetting(String(item.key).split(':')[1], String(item.key).split(':')[0], item.key)} /></td>
                      <td><img className="iconButton" src={require("./bidBoxPics/wasteBin.png")} onClick={() => showDeleteSystem(String(item.key).split(':')[1], String(item.key).split(':')[0], item.key)} /></td>
                    </tr>)
                })}
                {systemAsEditor.map(item => {
                  return (
                    <tr>
                      <td onClick={() => openSystem(String(item.key).split(':')[0], String(item.key).split(':')[1])}>{String(item.key).split(':')[1].length < 22 ? String(item.key).split(':')[1] : String(item.key).split(':')[1].slice(0, 20) + ".."}</td>
                      <td></td>
                      <td><img className="iconButton" src={require("./bidBoxPics/wasteBin.png")} onClick={() => showDeleteSystemEditor(String(item.key).split(':')[1], String(item.key).split(':')[0], item.key)} /></td>
                    </tr>)
                })}
              </table>
            </div>
          </div>
        </div>
      </div>

      {settingsVisible ?
        <div className="settingsBox" id="settingsBox">
          <div className="settingsBoxTop">
            <div></div>
            <div><b>{settingsSystemName}</b></div>
            <div><img className="exitButton" src={require("./bidBoxPics/exitButton.png")} onClick={() => setsettingsVisible(false)} /></div>
          </div>
          <div className="settingsBoxTopMid">
            {systemEditors.length > 0 ?
              <div>
                <div className="input-group">
                  <label className="inviteFriendsText">Invited friends:</label>
                  <select className="form-select w-2 invitedFriends" type="text" list="editors" onChange={changeEditorInFocus}>
                    {systemEditors.map(item => {
                      return (<option key={item} value={item}>{item}</option>);
                    })}
                  </select>
                  <button type="submit" className="btn btn-primary removeEditorButton" onClick={() => removeEditor()}>
                    Remove editor
                  </button>
                </div>
              </div>
              : null}
            <div>
              <label className="inviteFriendsText">Invite new friend:
                <input type="search" className="inviteFriendsSearch" list="inviteFriendsSearch" placeholder="Search"
                  onChange={event => searchFunction(event.target.value)}
                  value={currentUserToInvite}
                />
                <button type="submit" className="btn btn-primary inviteEditorButton" onClick={() => addEditor()}>
                  Invite
                </button>
              </label>
              <datalist id="inviteFriendsSearch">
                {searchingUsers.map(item => {
                  return <option value={item} key={item} onClick={() => setCurrentUserToInvite(item)} />
                })}
              </datalist>
            </div>
            <div>
              <label className="inviteFriendsText">Change system name:
                <input type="text" className="newSystemNameTextBox" placeholder="New system name"
                  onChange={event => setNewSystemNameChange(event.target.value)}
                  value={newSystemNameChange}
                />
                <button type="submit" className="btn btn-primary changeSystemNameButton" onClick={() => changeSystemName()}>
                  Change
                </button>
              </label>
              <p className="errorText">{deleteError}</p>
            </div>
          </div>
        </div>
        : null}
    </>
  );

  function searchFunction(searchString) {
    setCurrentUserToInvite(searchString)

    let newSearchArray = []
    if (searchString.length > 2) {

      for (let i = 0; i < users.length; ++i) {

        if (String(users[i]).toLowerCase().startsWith(searchString.toLowerCase())) {
          console.log(searchString)
          newSearchArray.push(users[i]);
        }
        if (newSearchArray.length > 10) {
          break;
        }
      }
    }
    setSearchingUsers(newSearchArray);
  }

  function showSystemSetting(name, id, key) {
    setSettingsSystemName(name)
    setSettingsSystemId(id)
    getAllEditors(id);
    setsettingsVisible(true);
  }

  function showDeleteSystem(name, id, key) {
    setDeleteSystemName(name)
    setDeleteSystemId(id)
    setDeleteSystemVisible(true)
    setToBeRemoved(id + ":" + name)
    console.log(name + id)
  }

  function showDeleteSystemEditor(name, id, key) {
    setDeleteSystemName(name)
    setDeleteSystemId(id)
    setDeleteSystemEditorVisible(true)
    setToBeRemoved(id + ":" + name)
  }

  function getAllUsers() {
    xhr = getXmlHttpRequestObject();
    xhr.onreadystatechange = getAllUsersCallback;
    var url = env.REACT_APP_HOST + "/getAllUsers?username=" + localStorage.getItem(env.REACT_APP_LOCAL_STORAGE_USERNAME) + "&token=" + localStorage.getItem(env.REACT_APP_LOCAL_STORAGE_USER_TOKEN);
    xhr.open("GET", url, true);
    xhr.send(null);
  }

  function getAllUsersCallback() {
    if (xhr.readyState == 4 && xhr.status == 200) {
      var response = JSON.parse(xhr.responseText);
      console.log("#Usrs: " + response)
      setUsers(response);
    }
  }

  function getAllEditors(systemId) {
    xhr = getXmlHttpRequestObject();
    xhr.onreadystatechange = getAllEditorsCallback;
    var url = env.REACT_APP_HOST + "/getAllEditors?username=" + localStorage.getItem(env.REACT_APP_LOCAL_STORAGE_USERNAME) + "&token=" + localStorage.getItem(env.REACT_APP_LOCAL_STORAGE_USER_TOKEN) + "&systemId=" + systemId;
    xhr.open("GET", url, true);
    xhr.send(null);
  }

  function getAllEditorsCallback() {
    if (xhr.readyState == 4 && xhr.status == 200) {
      var response = JSON.parse(xhr.responseText)
      let systemEditors = response
      console.log(systemEditors);
      setSystemEditors(systemEditors)
      if (systemEditors.length > 0) {
        setEditorInFocus(systemEditors[0][0]);
      }
    }
  }

  function addEditor() {
    for (let i = 0; i < systemEditors.length; ++i) {
      if (systemEditors[i] == currentUserToInvite) {
        setCurrentUserToInvite('')
        return;
      }
    }
    xhr = getXmlHttpRequestObject();
    xhr.onreadystatechange = addEditorCallback;
    var request = env.REACT_APP_HOST + "/addEditor"
    xhr.open("POST", request, true);
    xhr.setRequestHeader("Content-Type", "application/json;charset=UTF-8");
    xhr.send(JSON.stringify({ "username": localStorage.getItem(env.REACT_APP_LOCAL_STORAGE_USERNAME), "token": localStorage.getItem(env.REACT_APP_LOCAL_STORAGE_USER_TOKEN), "systemId": settingsSystemId, "editor": currentUserToInvite }));
  }

  function addEditorCallback() {
    if (xhr.readyState == 4 && xhr.status == 201) {
      getAllEditors(settingsSystemId);
      setCurrentUserToInvite('')
    }
  }

  function removeEditor() {
    xhr = getXmlHttpRequestObject();
    xhr.onreadystatechange = removeEditorCallback;
    var request = env.REACT_APP_HOST + "/removeEditor"
    xhr.open("POST", request, true);
    xhr.setRequestHeader("Content-Type", "application/json;charset=UTF-8");
    xhr.send(JSON.stringify({ "username": localStorage.getItem(env.REACT_APP_LOCAL_STORAGE_USERNAME), "token": localStorage.getItem(env.REACT_APP_LOCAL_STORAGE_USER_TOKEN), "systemId": settingsSystemId, "editor": editorInFocus }));
  }

  function removeEditorCallback() {
    if (xhr.readyState == 4 && xhr.status == 201) {
      getAllEditors(settingsSystemId);
    }
  }

  function createSystem() {
    xhr = getXmlHttpRequestObject();
    xhr.onreadystatechange = createSystemCallback;
    var request = env.REACT_APP_HOST + "/system"
    xhr.open("POST", request, true);
    xhr.setRequestHeader("Content-Type", "application/json;charset=UTF-8");
    xhr.send(JSON.stringify({ "username": localStorage.getItem(env.REACT_APP_LOCAL_STORAGE_USERNAME), "token": localStorage.getItem(env.REACT_APP_LOCAL_STORAGE_USER_TOKEN), "systemName": newSystemName }));
  }

  function createSystemCallback() {
    if (xhr.readyState == 4 && xhr.status == 201 && !String(xhr.responseText).includes("Invalid")) {
      setSystemAsOwner(items => [...items, { key: xhr.responseText }])
      setEnterNewSystemVisible(false)
      setNewSystemName("")
    }
    else if (xhr.readyState == 4) {
      setCreateError(xhr.responseText);
    }
  }

  function removeMyselfAsEditor() {
    xhr = getXmlHttpRequestObject();
    xhr.onreadystatechange = removeMyselfAsEditorCallback;
    var request = env.REACT_APP_HOST + "/removeEditor"
    xhr.open("POST", request, true);
    xhr.setRequestHeader("Content-Type", "application/json;charset=UTF-8");
    xhr.send(JSON.stringify({ "username": localStorage.getItem(env.REACT_APP_LOCAL_STORAGE_USERNAME), "token": localStorage.getItem(env.REACT_APP_LOCAL_STORAGE_USER_TOKEN), "systemId": deleteSystemId, "editor": localStorage.getItem(env.REACT_APP_LOCAL_STORAGE_USERNAME) }));
  }

  function removeMyselfAsEditorCallback() {
    if (xhr.readyState == 4 && xhr.status == 201 && !String(xhr.responseText).includes("Invalid")) {
      setSystemAsEditor([]);
      getSystemsAsEditor()
      setPassword("");
      setDeleteSystemEditorVisible(false)
    }
    else if (xhr.readyState == 4) {
      setDeleteError(xhr.responseText);
    }
  }

  function deleteSystem() {
    xhr = getXmlHttpRequestObject();
    xhr.onreadystatechange = deleteSystemCallback;
    var request = env.REACT_APP_HOST + "/systemDelete"
    xhr.open("POST", request, true);
    xhr.setRequestHeader("Content-Type", "application/json;charset=UTF-8");
    xhr.send(JSON.stringify({ "systemId": deleteSystemId, "password": password }));
  }

  function deleteSystemCallback() {
    if (xhr.readyState == 4 && xhr.status == 201 && !String(xhr.responseText).includes("Invalid")) {
      setSystemAsOwner([]);
      getSystemsAsOwner();
      setPassword("");
      setDeleteSystemVisible(false)
    }
    else if (xhr.readyState == 4) {
      setDeleteError(xhr.responseText);
    }
  }

  function openSystem(systemId, systemName) {
    sessionStorage.setItem(env.REACT_APP_LOCAL_STORAGE_CURRENT_SYSTEM_ID, systemId);
    sessionStorage.setItem(env.REACT_APP_LOCAL_STORAGE_CURRENT_SYSTEM_NAME, systemName);
    changeView("OpenSystem", systemId, systemName);
  }

  function changeView(view, systemId, systemName) {
    sessionStorage.setItem(env.REACT_APP_LOCAL_STORAGE_CURRENT_VIEW, view);
    window.location.href = '?page=' + view + "&systemId=" + systemId + "&systemName=" + systemName;
  }

  function getXmlHttpRequestObject() {
    if (!xhr) {
      xhr = new XMLHttpRequest();
    }
    return xhr;
  }

  function getSystemsAsOwner() {
    xhr = getXmlHttpRequestObject();
    xhr.onreadystatechange = dataCallback;
    var url = env.REACT_APP_HOST + "/getUserCreatedSystems?username=" + localStorage.getItem(env.REACT_APP_LOCAL_STORAGE_USERNAME) + "&token=" + localStorage.getItem(env.REACT_APP_LOCAL_STORAGE_USER_TOKEN)
    xhr.open("GET", url, true);
    xhr.send(null);
  }

  function dataCallback() {
    if (xhr.readyState == 4 && xhr.status == 200) {
      var response = JSON.parse(xhr.responseText)
      console.log(xhr.responseText);
      for (const system of response) {
        setSystemAsOwner(items => [...items, { key: system[0] + ":" + system[1] }])
      }
      getSystemsAsEditor();
    }
  }

  function getSystemsAsEditor() {
    xhr = getXmlHttpRequestObject();
    xhr.onreadystatechange = getSystemsAsEditorCallback;
    var url = env.REACT_APP_HOST + "/getUserEditorSystems?username=" + localStorage.getItem(env.REACT_APP_LOCAL_STORAGE_USERNAME) + "&token=" + localStorage.getItem(env.REACT_APP_LOCAL_STORAGE_USER_TOKEN)
    xhr.open("GET", url, true);
    xhr.send(null);
  }

  function getSystemsAsEditorCallback() {
    if (xhr.readyState == 4 && xhr.status == 200) {
      var response = JSON.parse(xhr.responseText)
      console.log(response)
      setSystemAsEditor([]);
      for (const system of response) {
        setSystemAsEditor(items => [...items, { key: system[0] + ":" + system[1] }])
      }
      getAllUsers();
    }
  }

  function changeSystemName() {
    xhr = getXmlHttpRequestObject();
    xhr.onreadystatechange = changeSystemNameCallback;
    var request = env.REACT_APP_HOST + "/systemChangeName"
    xhr.open("POST", request, true);
    xhr.setRequestHeader("Content-Type", "application/json;charset=UTF-8");
    console.log(JSON.stringify({ "username": localStorage.getItem(env.REACT_APP_LOCAL_STORAGE_USERNAME), "token": localStorage.getItem(env.REACT_APP_LOCAL_STORAGE_USER_TOKEN), "systemId": settingsSystemId, "newSystemName": newSystemNameChange }));
    xhr.send(JSON.stringify({ "username": localStorage.getItem(env.REACT_APP_LOCAL_STORAGE_USERNAME), "token": localStorage.getItem(env.REACT_APP_LOCAL_STORAGE_USER_TOKEN), "systemId": settingsSystemId, "newSystemName": newSystemNameChange }));
  }

  function changeSystemNameCallback() {
    console.log(xhr.responseText);
    if (xhr.readyState == 4 && xhr.status == 201) {
      setSystemAsOwner([]);
      getSystemsAsOwner();
      setNewSystemNameChange("");
    }
    else if (xhr.readyState == 4) {
      setDeleteError(xhr.responseText);
    }
  }
}
