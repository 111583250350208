import React, {useState, useEffect} from 'react'
import "./Profile.css"
import env from "react-dotenv";

export default function Profile() {
    var xhr = null;
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [username, setUsername] = useState('');
    useEffect(() => {
        getUsers();
    }, [])
  return (
      <div className='bgDiv'>
      <div className="Profile-container">
      <div className="Profile">
        <div className="Content"> 
          <h3 className="Title">Profile</h3>
          <div className="mt-3">
            <label>Username: &nbsp;&nbsp;{username}</label>
                
          </div>
          <div className="mt-3">
            <label>Name: &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{name}</label>
            
          </div>
          <div className="mt-3">
            <label>Email: &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{email}</label>
            
          </div>
          <div className="d-grid gap-2 mt-3">
            <button  className="btn btn-primary" onClick={() => changeView('ChangePassword')}>
              Change password
            </button>
          </div>
          
        </div>
      </div>
    </div>
    </div>

  )
  function changeView(view){
    sessionStorage.setItem(env.REACT_APP_LOCAL_STORAGE_CURRENT_VIEW, view);
    window.location.href = '?page=' + view;
    // window.location.reload(false);
  }
    function getXmlHttpRequestObject() {
        if (!xhr) {
            // Create a new XMLHttpRequest object 
            xhr = new XMLHttpRequest();
        }
        return xhr;
    };
  function getUsers() {
    xhr = getXmlHttpRequestObject();
    xhr.onreadystatechange = dataCallback;
    // asynchronous requests
    var url = env.REACT_APP_HOST + "/getUserInfo?username=" +  localStorage.getItem(env.REACT_APP_LOCAL_STORAGE_USERNAME) + "&token=" + localStorage.getItem(env.REACT_APP_LOCAL_STORAGE_USER_TOKEN)
    xhr.open("GET", url, true);
    // Send the request over the network
    xhr.send(null);
  }
  function dataCallback() {
    // Check response is ready or not
    if (xhr.readyState == 4 && xhr.status == 200) {
        // Log to console
        console.log(xhr.responseText);
        var response = JSON.parse(xhr.responseText)
        var response2 = JSON.parse(response) //WTF?
        setEmail(response2.email);
        setName(response2.name);
        setUsername(localStorage.getItem(env.REACT_APP_LOCAL_STORAGE_USERNAME));
    }
}
}
